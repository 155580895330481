<template>
  <router-view />
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'App',

    created () {
      axios.interceptors.request.use((config) => {
        // Do something before request is sent
        this.$store.commit('LOADER', true)
        return config
      }, (error) => {
        // Do something with request error
        this.$store.commit('LOADER', false)

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {
        this.$store.commit('LOADER', false)
        return response
      }, (error) => {
        this.$store.commit('LOADER', false)

        if (error.response.status !== 401) {
          return Promise.reject(error)
        } else {
          this.$store.dispatch('logout')
            .then(() => this.$router.push('/login'))
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            })
        }
      })
    },
  }
</script>
